<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="车辆编号" prop="vehicleId">
        <a-input disabled v-model="form.vehicleId" placeholder="请输入车辆编号" />
      </a-form-model-item>
      <a-form-model-item label="保养名称" prop="name">
        <a-input disabled v-model="form.name" placeholder="请输入保养名称" />
      </a-form-model-item>
      <a-form-model-item label="型号" prop="model">
        <a-input disabled v-model="form.model" placeholder="请输入型号" />
      </a-form-model-item>
      <a-form-model-item label="车辆保养时间" prop="upkeepTime">
        <a-date-picker
          style="width: 100%"
          v-model="form.upkeepTime"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="车辆保养里程(KM)" prop="odom">
        <a-input v-model="form.odom" placeholder="请输入车辆保养里程" />
      </a-form-model-item>
      <a-form-model-item label="保养门店名称" prop="upkeepStore">
        <a-input v-model="form.upkeepStore" placeholder="请输入保养门店名称" />
      </a-form-model-item>
      <a-form-model-item label="保养地址" prop="upkeepAddress">
        <a-input v-model="form.upkeepAddress" placeholder="请输入保养地址" />
      </a-form-model-item>
      <a-form-model-item label="车辆保养费用" prop="upkeepCost">
        <a-input v-model="form.upkeepCost" placeholder="请输入车辆保养费用" />
      </a-form-model-item>
      <a-form-model-item label="保养处理人" prop="upkeepPerson">
        <a-input v-model="form.upkeepPerson" placeholder="请输入保养处理人" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm"> 保存 </a-button>
          <a-button type="dashed" @click="cancel"> 取消 </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getUpkeep, addUpkeep, updateUpkeep } from '@/api/iot/upkeep'
import { getRecord, editRecord } from '@/api/iot/record'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        vehicleId: null,
        name: null,
        model: null,
        upkeepTime: null,
        upkeepTimeNext: null,
        odom: null,
        odomUpkeep: null,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        vehicleId: [{ required: true, message: '车辆编号不能为空', trigger: 'blur' }]
      }
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        vehicleId: null,
        name: null,
        model: null,
        upkeepTime: null,
        upkeepTimeNext: null,
        cycleUpkeep: null,
        odom: null,
        odomUpkeep: null,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      this.form.vehicleId = this.$route.query.vehicleId
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getRecord(id).then((response) => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            editRecord(this.form).then((response) => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            // addUpkeep(this.form).then((response) => {
            //   this.$message.success('新增成功', 3)
            //   this.open = false
            //   this.$emit('ok')
            // })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
