var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆编号", prop: "vehicleId" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入车辆编号" },
                model: {
                  value: _vm.form.vehicleId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "vehicleId", $$v)
                  },
                  expression: "form.vehicleId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "保养名称", prop: "name" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入保养名称" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "型号", prop: "model" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入型号" },
                model: {
                  value: _vm.form.model,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "model", $$v)
                  },
                  expression: "form.model",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆保养时间", prop: "upkeepTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD HH:mm:ss",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.upkeepTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "upkeepTime", $$v)
                  },
                  expression: "form.upkeepTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆保养里程(KM)", prop: "odom" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入车辆保养里程" },
                model: {
                  value: _vm.form.odom,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "odom", $$v)
                  },
                  expression: "form.odom",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "保养门店名称", prop: "upkeepStore" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入保养门店名称" },
                model: {
                  value: _vm.form.upkeepStore,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "upkeepStore", $$v)
                  },
                  expression: "form.upkeepStore",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "保养地址", prop: "upkeepAddress" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入保养地址" },
                model: {
                  value: _vm.form.upkeepAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "upkeepAddress", $$v)
                  },
                  expression: "form.upkeepAddress",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆保养费用", prop: "upkeepCost" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入车辆保养费用" },
                model: {
                  value: _vm.form.upkeepCost,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "upkeepCost", $$v)
                  },
                  expression: "form.upkeepCost",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "保养处理人", prop: "upkeepPerson" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入保养处理人" },
                model: {
                  value: _vm.form.upkeepPerson,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "upkeepPerson", $$v)
                  },
                  expression: "form.upkeepPerson",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入备注" },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }